import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchStatistics } from "store/statistics/statistics.reducer";
import { fetchFormats } from "store/formats/formats.reducer";
import { fetchLevels } from "store/levels/levels.reducer";
import { fetchSpecialization } from "store/specializations/specializations.reducer";
import { selectSpecializationsOptions } from "store/specializations/specializations.selector";
import { selectFormatsOptions } from "store/formats/formats.selector";
import {
  selectLevelsLabel,
  selectLevelsOptions,
} from "store/levels/levels.selector";

import { useFilters } from "hooks/filters.hooks";
import { filterKey } from "util/filterKeys";

import { FilterWrapper } from "./Filter.styled";
import FilterItem from "./FilterItem";
import {
  fetchRatingByLevels,
  fetchRatingBySpecialization,
} from "store/rating/rating.reducer";
import { useRegion } from "hooks/region.hooks";

function Filters() {
  const dispatch = useDispatch();
  const [filter, setFilter] = useFilters();
  const [specLevel, setSpecLevel] = useState<any>(null);
  const region = useRegion();
  const levelValue = useSelector(selectLevelsOptions(specLevel));

  // Обновляем статистику по стране
  useEffect(() => {
    dispatch(
      fetchStatistics({ filters: filter, params: { region_id: region?.id } })
    );
  }, [dispatch, filter]);

  const handleChange = useCallback(
    (key: string, value: number) => {
      // Сбрасываем фильтр специализации при изменении уровня
      // if (key === filterKey.level) {
      // setFilter({ [filterKey.specialization]: "", [key]: value });
      // } else {

      setFilter({
        [key]: value,
      });
      // }
    },
    [setFilter]
  );

  const handleSelect = useCallback(
    (key: string, value: any) => {
      setSpecLevel(
        //   {
        //   [key]: value,
        // }
        value.level
      );
    },
    [setSpecLevel]
  );

  return (
    <FilterWrapper>
      <FilterItem
        filterKey={filterKey.specialization}
        value={filter.specialization_code}
        placeholder="Специализация"
        onChange={handleChange}
        onSelect={handleSelect}
        onClear={() => setSpecLevel(null)}
        selector={selectSpecializationsOptions(+filter.education_level)}
        action={fetchSpecialization}
      />
      <FilterItem
        filterKey={filterKey.level}
        value={specLevel ? levelValue[0].label : filter.education_level}
        placeholder="Уровень образования"
        onChange={handleChange}
        selector={selectLevelsOptions(specLevel)}
        action={fetchLevels}
      />
      <FilterItem
        filterKey={filterKey.education}
        value={filter.education_type}
        placeholder="Форма обучения"
        onChange={handleChange}
        selector={selectFormatsOptions}
        action={fetchFormats}
      />
    </FilterWrapper>
  );
}

export default React.memo(Filters);
