import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "..";

export const selectSpecializations = (state: RootState) => state.specialization;

export const selectSpecializationsEntities = createSelector(
  selectSpecializations,
  (state) => state.entities
);

export const selectSpecializationsOptions = (level: number) =>
  createSelector(selectSpecializations, (state) =>
    state.entities
      .filter((item) => (!isNaN(level) ? item.level === level : true))
      .map((item) => ({
        level: item.level,
        value: item.code,
        label: `${item.code} ${item.name}`,
        key: item.code,
      }))
      .sort((a, b) => a.label.localeCompare(b.label, "ru"))
  );

export const selectPositionById = (id?: number) =>
  createSelector(selectSpecializations, (state) =>
    state.entities.find((item) => item.id === id)
  );
