import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "..";

export const selectLevels = (state: RootState) => state.levels;

export const selectLevelsEntities = createSelector(
  selectLevels,
  (state) => state.entities
);

export const selectLevelsOptions = (level: number) =>
  createSelector(selectLevels, (state) => {
    const result = state.entities
      .map((item: any) => ({ value: item.id, label: item.name }))
      .filter((item: any) => (level ? item.value === level : item))
      .sort((a: any, b: any) => a.label.localeCompare(b.label, "ru"));

    return result;
  });

export const selectLevelsLabel = (level: number) =>
  createSelector(selectLevels, (state) => {
    const result = state.entities
      .map((item: any) => ({ value: item.id, label: item.name }))
      .filter((item: any) => (level ? item.value === level : item))
      .sort((a: any, b: any) => a.label.localeCompare(b.label, "ru"));

    return result[0].label;
  });

export const selectFieldById = (id?: number) =>
  createSelector(selectLevels, (state) =>
    state.entities.find((item: any) => item.id === id)
  );
